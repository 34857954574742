import { gql } from '@apollo/client';

export const updateCommitteeInvitationQuery = gql`
  mutation updateCommitteeInvitation($input: UpdateCommitteeInvitationInput!) {
    updateCommitteeInvitation(input: $input) {
      success
      message
    }
  }
`;
