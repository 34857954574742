import { useMutation } from '@apollo/client';
import {
  UpdateCommitteeInvitationMutation,
  UpdateCommitteeInvitationMutationVariables,
} from '@typings/operations';
import { updateCommitteeInvitationQuery } from '../queries/updateCommitteeInvitation';

export const useUpdateCommitteeInvitation = () =>
  useMutation<
    UpdateCommitteeInvitationMutation,
    UpdateCommitteeInvitationMutationVariables
  >(updateCommitteeInvitationQuery);
