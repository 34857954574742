import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Collapse from '@kunukn/react-collapse';

import Icon from '@components/Icon';
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import { CommitteeNominations } from '@typings/committeeNominations';

interface MobileInvitationsListProps {
  committeeNominationsData: CommitteeNominations[];
  handleRespondInvitations?: (invitations: CommitteeNominations) => {};
  handleViewLink?: (invitations: CommitteeNominations) => {};
  isPresRepCommittee?: boolean;
}

const MobileInvitationsList: React.FC<MobileInvitationsListProps> = ({
  committeeNominationsData,
  handleRespondInvitations,
  handleViewLink,
  isPresRepCommittee,
}) => {
  const [expandedResult, setExpandedResult] = useState<string | null>(null);

  const handleCollapse = (uniqueIdentifier: string) => {
    if (expandedResult !== uniqueIdentifier) {
      setExpandedResult(uniqueIdentifier);
    } else {
      setExpandedResult(null);
    }
  };

  return (
    <ResultsList headerClassName="px-4 bg-gray-100">
      {committeeNominationsData.map((invitations, index) => {
        const uniqueIdentifier = `${invitations?.nom_key}-${index}`;
        const isExpanded = Boolean(expandedResult === uniqueIdentifier);

        const respondButtonContent = handleRespondInvitations?.(invitations);

        return (
          <ResultsListItem key={uniqueIdentifier}>
            <div
              className={classNames(
                `invitations-row-mobile desktop:hidden w-full px-5`,
                {
                  'bg-bright-blue-100 pb-8 -my-4 pt-5': isExpanded,
                  'invitations-row-mobile--expanded': isExpanded,
                }
              )}
            >
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (respondButtonContent) {
                    handleCollapse(uniqueIdentifier);
                  }
                }}
                className={classNames(
                  'relative w-full invitations-row-info flex items-center text-left text-xs font-normal leading-6 focus:shadow-none tap-highlight-color-transparent overflow-hidden'
                )}
                data-testid="toggle-expand-btn"
              >
                <div>
                  <p className="text-md leading-6 mb-0 mr-4">
                    {isPresRepCommittee
                      ? invitations?.org_name
                      : invitations?.cmt_name}
                  </p>
                  <p className="mb-0">
                    {isPresRepCommittee
                      ? invitations?.rc5_location_city_state
                      : invitations?.cpo_code}
                  </p>
                  {isPresRepCommittee && (
                    <p className="text-xs mb-0">
                      {moment(invitations?.rc5_start_date).format(
                        'DD MMM YYYY'
                      )}
                    </p>
                  )}
                  <p className="mb-0">{invitations?.nms_code}</p>
                  {handleViewLink?.(invitations)}
                  <div
                    aria-hidden="true"
                    className={classNames('absolute right-0 top-1', {
                      hidden: isExpanded || !respondButtonContent,
                    })}
                  >
                    <Icon name="arrows/down" size="17" color="black" />
                  </div>
                  <div
                    aria-hidden="true"
                    className={classNames('absolute right-0 top-1', {
                      hidden: !isExpanded || !respondButtonContent,
                    })}
                  >
                    <Icon name="ui/close" size="17" color="black" />
                  </div>
                </div>
              </div>

              <Collapse isOpen={isExpanded} className="collapse-css-transition">
                <div className="w-full">{respondButtonContent}</div>
              </Collapse>
            </div>
          </ResultsListItem>
        );
      })}
    </ResultsList>
  );
};

export default MobileInvitationsList;
