import React, { useState } from 'react';

import { RouteComponentProps, useLocation } from '@reach/router';
import { Form, Formik } from 'formik';
import moment from 'moment';

import { Button } from '@components/Button';
import LabelWithValue from '@components/LabelWithValue';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import Title from '@components/Title';

import { getBackButtonLabel } from '@use-cases/clubs';
import { useModal, useStopBrowserNavigate } from '@use-cases/districts';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { CommitteeNominations } from '@typings/graphql';
import { useUpdateCommitteeInvitation } from '@repositories/committeeNominations';
import { CommitteeInvitationStatus } from '@typings/resolvers';
import { TFunction } from 'i18next';
import { RadioOption } from '@components/Formik/RadioField';
import { Radios } from '@components/Forms/Radios';
import { NMS_TYPE_PRES_REP } from '@backend/schema/CommitteeNominations/constants';
import { getCommitteeInvitationsPath } from '@use-cases/sharepoint/helpers';

const RespondCommitteeInvitation: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [statusValue, setStatusValue] = useState<string>('');

  const location = useLocation();
  const respondInvitationData = (location?.state as Record<
    string,
    CommitteeNominations
  >)?.invitationsList;

  const modalOnConfirmHandler = () => {
    localizedNavigate(getCommitteeInvitationsPath());
  };

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const handleCancelBtnClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    show(true);
  };

  const [
    updateCommitteeInvitation,
    { loading: isUpdateInvitationLoading },
  ] = useUpdateCommitteeInvitation();

  if (isUpdateInvitationLoading) {
    return <Loading />;
  }

  const optionsToRespondInvitation = (t: TFunction): RadioOption[] => [
    {
      value: CommitteeInvitationStatus.Accepted,
      label: t('invitations.respond-option-accept', 'Accept'),
    },
    {
      value: CommitteeInvitationStatus.Declined,
      label: t('invitations.respond-option-decline', 'Decline'),
    },
  ];

  const isPresRepCommittee =
    respondInvitationData?.cpo_code === NMS_TYPE_PRES_REP;

  const DATE_FORMAT = 'DD-MM-YYYY';
  const distConfStartDate = respondInvitationData?.rc5_start_date
    ? moment(new Date(respondInvitationData?.rc5_start_date)).format(
        DATE_FORMAT
      )
    : '';
  const distConfEndDate = respondInvitationData?.rc5_end_date
    ? moment(new Date(respondInvitationData?.rc5_end_date)).format(DATE_FORMAT)
    : '';

  const distConfVenueLocationCityState = respondInvitationData?.rc5_location
    ? `${respondInvitationData?.rc5_location}, ${respondInvitationData?.rc5_location_city_state}`
    : `${respondInvitationData?.rc5_location_city_state}`;

  const committeeDescription = isPresRepCommittee
    ? t(
        'invitations.respond-pres-rep-committee-description',
        'The RI president has invited you to represent them and their message for the Rotary year at the following district conference. Review the location, dates, and times of the district conference and inform us of your ability to represent the president by choosing “Accept” or “Decline”.'
      )
    : t('invitations.respond-ri-committee-name', '{{committeeName}}', {
        committeeName: respondInvitationData?.cmt_name,
      });

  const handleChangeStatus = (status: string) => {
    setStatusValue(status);
  };

  const handleSubmit = async () => {
    window.scrollTo(0, 0);
    await updateCommitteeInvitation({
      variables: {
        input: {
          nom_key: respondInvitationData?.nom_key || '',
          nom_status_key: statusValue as CommitteeInvitationStatus,
        },
      },
    });
    localizedNavigate(getCommitteeInvitationsPath());
  };

  return (
    <>
      <div className="mb-5">
        <Title>{t('invitations.my-invitations.title', 'My Invitations')}</Title>
      </div>
      <div className="mt-4">
        <LinkPrevious
          path=""
          label={getBackButtonLabel(t)}
          showModal={modalBackHandler}
        />
        <LeaveFormConfirmationModal
          isOpen={isShowing}
          closeModal={globalHide}
          onConfirm={modalOnConfirmHandler}
        />

        <Title className="h2 desktop:mb-7 mobile:mb-4">
          {t('invitations.respond-to-invitation', 'Respond to invitation')}
        </Title>
        <div className="text-base mt-6 my-6 tablet:mt-6">
          <p className="m-0 text-md leading-md">{committeeDescription}</p>
        </div>
        <Formik
          initialValues={{ invitationStatus: '' }}
          onSubmit={handleSubmit}
        >
          {() => {
            return (
              <Form>
                <div className="max-w-lg desktop:mb-4 mobile:mb-3">
                  {isPresRepCommittee ? (
                    <>
                      <LabelWithValue
                        label={t(
                          'invitation.respond-district-conference',
                          'District Conference'
                        )}
                        value={respondInvitationData?.org_name}
                      />
                      <LabelWithValue
                        label={t(
                          'invitation.respond-district-conference-start-date-time',
                          'Start date and time'
                        )}
                        value={`${distConfStartDate} ${respondInvitationData?.rc5_start_time}`}
                      />
                      <LabelWithValue
                        label={t(
                          'invitation.respond-district-conference-end-date-time',
                          'End date and time'
                        )}
                        value={`${distConfEndDate} ${respondInvitationData?.rc5_end_time}`}
                      />
                      <LabelWithValue
                        label={t(
                          'invitation.respond-district-conference-location',
                          'Venue, City, State/Province, Country'
                        )}
                        value={distConfVenueLocationCityState}
                      />
                      <LabelWithValue
                        label={t(
                          'invitation.respond-district-conference-language',
                          'Language'
                        )}
                        value={respondInvitationData?.r00_language_name}
                      />
                    </>
                  ) : (
                    <LabelWithValue
                      label={t(
                        'invitation.respond-committee-position',
                        'Recommended to serve as'
                      )}
                      value={respondInvitationData?.cpo_code}
                    />
                  )}
                  <div className="flex flex-col w-full">
                    <Radios
                      id="invitationStatus"
                      name="invitationStatus"
                      legend={t(
                        'invitations.response-to-invitation-label',
                        'Response to invitation'
                      )}
                      value={statusValue}
                      options={optionsToRespondInvitation(t)}
                      changeHandler={handleChangeStatus}
                      classes={{
                        radioBtn: 'z-0',
                        optionWrapper: 'my-4',
                      }}
                    />
                  </div>
                  <div className="flex flex-col desktop:mt-13 mobile:mt-12">
                    <Button full disabled={false}>
                      {t('invitations.respond-save', 'SAVE')}
                    </Button>
                    <Button
                      clickHandler={handleCancelBtnClick}
                      text
                      className="mt-8"
                    >
                      {t('invitations.respond-cancel', 'CANCEL')}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default RespondCommitteeInvitation;
