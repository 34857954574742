import { gql } from '@apollo/client';

export const fetchCommitteeInvitationsQuery = gql`
  query getCommitteeInvitations {
    result: getCommitteeInvitations {
      cmt_name
      cmt_asn_code
      cpo_code
      nms_code
      nom_key
      nom_cmt_key
      nom_cst_key
      nom_nms_key
      nom_start_date
      nom_end_date
      nom_status_chg_date
      org_name
      rc5_start_date
      rc5_end_date
      rc5_start_time
      rc5_end_time
      rc5_location
      rc5_location_city_state
      r00_language_name
      rc6_primary_flag
      nom_accepted_flag
      nom_rejected_flag
    }
  }
`;
